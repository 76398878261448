import translate from "@src/i18n"
import { Button } from "antd"
import cx from "classnames"
import React from "react"

import styles from "../style.module.less"

const CancelPlan = (props) => {
  const {
    handleCancelPlanVisible,
    cancelPlan,
    validateDate,
    instantCancel,
    loading,
  } = props

  return (
    <div
      className={cx(
        "pt40 tc flex flex-column h-100 cancelPlanContext",
        styles.mainContainer
      )}
    >
      <span className={styles.form_title}>
        {translate("cancelSubscription")}
      </span>
      <div className="flex flex-column justify-between">
        <div className={cx("mb32", styles.descriptionContainer)}>
          {!instantCancel ? (
            <span className={(styles.form_subtitle, styles.widthDescription)}>
              {translate("subscriptionDuration")}
              <span className={styles.finalDate}> {` ${validateDate}`}</span>
            </span>
          ) : (
            <span className={(styles.form_subtitle, styles.widthDescription)}>
              {translate("subscriptionInstantCancel")}
            </span>
          )}
        </div>
        <Button
          type="primary"
          onClick={cancelPlan}
          className={styles.button}
          loading={loading}
        >
          {!instantCancel ? translate("cancel") : translate("confirm")}
        </Button>
      </div>
    </div>
  )
}

export default CancelPlan
