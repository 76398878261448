import ChangePhone from "@components/changePhone"
import ChangeProfilePhoto from "@components/changePhoto"
import { CustomModalErrors } from "@components/CustomModalErrors"
import { Icon } from "@components/Icons"
import MyAccountModal from "@components/myAccountModal"
import { SuccessModal } from "@components/SuccessModal"
import { SuccessModalStylized } from "@components/SuccessModalStylized"
import TranslateWrapper from "@components/TranslateWrapper"
import { useStores } from "@hooks/use-stores"
import ChangePassword from "@modules/Auth/Site/components/ChangePasswordForm"
import ManagePlan from "@modules/MyAccount/ManagePlan"
import ManagePublicEmail from "@modules/MyAccount/ManagePublicEmail"
import { errorsAdapter } from "@modules/Registration/SubscriberRegistration/adapters/registration"
import translate from "@src/i18n"
import { maskPhone } from "@utils/strings"
import { Avatar, Card, Col, Modal, Row } from "antd"
import cx from "classnames"
import _get from "lodash/get"
import _map from "lodash/map"
import _uniqBy from "lodash/uniqBy"
import moment from "moment"
import React, { useEffect, useMemo, useState } from "react"
import { Helmet } from "react-helmet"
import { useMedia } from "react-use-media"

import styles from "./style.module.less"

const SubscriberAccount = ({ location }) => {
  const { hash } = location
  const [visible, setVisible] = useState(false)
  const [managePlanVisible, setManagePlanVisible] = useState(false)
  const [modalContent, setModalContent] = useState()
  const [isClient, setIsClient] = useState(false)
  const [changePhotoVisible, setChangePhotoVisible] = useState(false)
  const [dataSub, setDataSub] = useState(null)
  const [newPhone, setNewPhone] = useState(null)
  const [successMessagePhoneAndPassword, setSuccessMessagePhoneAndPassword] =
    useState(null)
  const [successMessagePlanCanceled, setSuccessMessagePlanCanceled] =
    useState(null)
  const [successMessageAll, setSuccessMessageAll] = useState(null)
  const [areaCode, setAreaCode] = useState([])
  const [countries, setCountries] = useState([])
  const [planName, setPlanName] = useState("")
  const [planValue, setPlanValue] = useState(null)
  const [validateDate, setValidateDate] = useState("")
  const [suspensePlan, setSuspensePlan] = useState(false)
  const [amountPlan, setAmountPlan] = useState(false)
  const [errors, setErrors] = useState([])

  const { authSite, profile, registrationAffiliated } = useStores()

  const isDesktop = useMedia({ minWidth: 1024 })
  const isMobileMini = useMedia({ maxWidth: 320 })
  const isTablet = useMedia({ minWidth: 768, maxWidth: 1024 })
  const isDesktopMini = useMedia({ minWidth: 1024, maxWidth: 1365 })
  const isMobile = useMedia({ maxWidth: 767 })

  const { logout, user } = authSite
  const { getDataSubById } = profile

  const { getAllCountriesAreaCode } = registrationAffiliated

  const terms = process.env.AWS_TERMS
  const privacy = process.env.AWS_PRIVACY

  const data = {
    id: _get(dataSub, "id", ""),
    urlProfile: _get(dataSub, "urlPhoto", ""),
    name: _get(dataSub, "name", ""),
    nickname: _get(dataSub, "nickname", ""),
    phoneNumber: _get(dataSub, "phone", ""),
    subscriptions: _get(dataSub, "subscriptions", ""),
    address: _get(dataSub, "address", ""),
    email: _get(dataSub, "email", ""),
    username: _get(dataSub, "username", ""),
  }

  const srcProfile = `${process.env.S3_IMAGES}/${data.urlProfile}`

  useMemo(() => {
    const isUserInactive = hash === "#inactive=true" ? true : false
    if (isUserInactive) {
      const error = translate("inactiveSubscriptionErrorText")
      const adaptedErrors = errorsAdapter(error)
      history.replaceState(null, null, " ")
      setErrors(adaptedErrors)
    }
  }, [hash])

  useEffect(() => {
    handleGetDataSub()
    retrieveAllCountriesAreaCode()
    setIsClient(true)
  }, [])

  useEffect(() => {
    handlePhoneNumber()
  }, [data])

  const handleClose = () => {
    setModalContent(null)
    setVisible(false)
  }

  const handleGetDataSub = async () => {
    setDataSub(null)
    const id = _get(user, "id", "")

    const subscriber = await getDataSubById(id)
    setDataSub(subscriber)

    if (subscriber?.status !== user.status) {
      user.status = subscriber.status
    }
    if (
      subscriber.statusSubscription !== "CANCELED" &&
      subscriber.statusSubscription !== "EXPIRED" &&
      subscriber.statusSubscription
    ) {
      setPlanName(subscriber.activePlan)
      setPlanValue(subscriber.planValue)
      setAmountPlan(subscriber.planValue)
      const date = moment(subscriber.endSubscription).format("DD/MM/YYYY")
      setValidateDate(date)
      setSuspensePlan(false)
    } else {
      setSuspensePlan(true)
    }
  }

  const retrieveAllCountriesAreaCode = async () => {
    const response = await getAllCountriesAreaCode()
    const arrayDataAreaCode = []
    const arrayDataCountries = []

    _map(response, (item) => {
      const dataAreaCode = { id: item.id, code: item.areaCode }
      const dataCountries = { id: item.id, name: item.nameCountry }

      arrayDataAreaCode.push(dataAreaCode)
      arrayDataCountries.push(dataCountries)
    })

    const uniqByAreaCode = _uniqBy(arrayDataAreaCode, "code")
    uniqByAreaCode.sort((a, b) => {
      return a.code - b.code
    })

    setAreaCode(uniqByAreaCode)
    setCountries(arrayDataCountries)
  }

  const handlePhoneNumber = () => {
    if (data.phoneNumber.length === 12) {
      const phone = data.phoneNumber.slice(1)
      const newPhone = maskPhone(phone)
      setNewPhone(newPhone)
    }
    if (data.phoneNumber.length === 13) {
      const phone = data.phoneNumber.slice(2)
      const newPhone = maskPhone(phone)
      setNewPhone(newPhone)
    }
    if (data.phoneNumber.length === 14) {
      const phone = data.phoneNumber.slice(3)
      const newPhone = maskPhone(phone)
      setNewPhone(newPhone)
    }
    if (data.phoneNumber.length === 15) {
      const phone = data.phoneNumber.slice(4)
      const newPhone = maskPhone(phone)
      setNewPhone(newPhone)
    }
  }

  const handleManagePlanVisible = () => {
    setManagePlanVisible((prev) => !prev)
  }

  const handleClickLogout = () => logout()

  if (!isClient) return null

  const switchRender = (option) => {
    setVisible(true)

    switch (option) {
      case "password":
        setModalContent(
          <ChangePassword
            handleClose={handleClose}
            setModalContent={setModalContent}
            setSuccessMessage={setSuccessMessagePhoneAndPassword}
            data={data}
            areaCode={areaCode}
          />
        )
        return

      case "phone":
        setModalContent(
          <ChangePhone
            handleClose={handleClose}
            setModalContent={setModalContent}
            data={data}
            setVisible={setVisible}
            setSuccessMessage={setSuccessMessagePhoneAndPassword}
            areaCode={areaCode}
            countries={countries}
            isSubscriber
          />
        )
        return

      case "name":
        setModalContent(
          <ManagePublicEmail
            data={data}
            handleClose={handleClose}
            handleGetDataSub={handleGetDataSub}
            setSuccessMessage={setSuccessMessageAll}
            isName
            isSubscriber
          />
        )
        return

      case "nickname":
        setModalContent(
          <ManagePublicEmail
            data={data}
            handleClose={handleClose}
            handleGetDataSub={handleGetDataSub}
            setSuccessMessage={setSuccessMessageAll}
            isNickname
            isSubscriber
          />
        )
        return
      case "username":
        setModalContent(
          <ManagePublicEmail
            data={data}
            handleClose={handleClose}
            handleGetDataSub={handleGetDataSub}
            setSuccessMessage={setSuccessMessageAll}
            isUserName
            isSubscriber
          />
        )
        return

      case "email":
        setModalContent(
          <ManagePublicEmail
            data={data}
            handleClose={handleClose}
            handleGetDataSub={handleGetDataSub}
            setSuccessMessage={setSuccessMessageAll}
            isEmail
            isSubscriber
          />
        )
        return

      // case "language":
      //   setModalContent(<ChangeLanguage />)
      //   return

      case "plan":
        setVisible(false)
        setManagePlanVisible(true)
        return

      default:
        return
    }
  }

  return (
    <>
      <TranslateWrapper>
        <Helmet>
          <title>Minha Conta (Assinante) - Anonimatta</title>
        </Helmet>
        <div className="page">
          <div
            className={cx("mc center subscriberAccount", styles.contentPage)}
          >
            <div className={styles.containerSubscriber}>
              <div className={styles.profileContainer}>
                <div
                  className={styles.avatarSize}
                  style={{
                    backgroundImage: `url("${srcProfile}")`,
                  }}
                >
                  <button
                    className={styles.newPhoto}
                    onClick={() => setChangePhotoVisible(true)}
                  >
                    <img
                      className="pointer"
                      src={require("@images/icons/camera.svg")}
                      width="24px"
                      height="30px"
                      alt="Alterar foto"
                    />
                  </button>
                </div>
                <div className={styles.profileName}>
                  <label>{data.name}</label>
                </div>
              </div>

              <Row
                gutter={[isMobileMini ? 12 : 24, isMobileMini ? 0 : 24]}
                className={styles.containerCards}
              >
                <Col order={1} xs={24} sm={24} md={12} lg={12} xl={8}>
                  <div className={styles.sepatorCard}></div>
                  <div className={cx(styles.gridWidth)}>
                    <Card
                      className={cx(
                        "anonimatta-card-subscriber",
                        styles.colorCard
                      )}
                    >
                      <div className="flex items-center">
                        <img
                          className="pointer"
                          src={require("@images/icons/plan.svg")}
                          width="24px"
                          height="24px"
                          alt="Plano"
                        />

                        <label className={cx("", styles.accountLabel)}>
                          {translate("plan")}
                        </label>
                      </div>
                      <div className={styles.contentCard}>
                        {/* 3 */}
                        <div
                          className={styles.sectionContainer}
                          onClick={() => switchRender("plan")}
                        >
                          <div className={styles.containerLabels}>
                            <label className={cx("", styles.labelCard)}>
                              {planName
                                ? translate("managePlan")
                                : translate("havePlan")}
                            </label>
                            <label className={cx(styles.labelCardInfo)}>
                              {planName}
                            </label>
                          </div>
                          <Icon name="Down" className={styles.iconDown} />
                        </div>
                      </div>
                    </Card>
                  </div>
                </Col>
                <Col
                  order={isTablet || isDesktopMini ? 3 : 2}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                >
                  <div className={styles.sepatorCard}></div>
                  <div className={styles.gridWidth}>
                    <Card
                      className={cx(
                        "anonimatta-card-subscriber",
                        styles.colorCard
                      )}
                    >
                      <div className="flex items-center">
                        <img
                          name="CreditCard"
                          className="pointer"
                          src={require("@images/icons/public-data.svg")}
                          width="24px"
                          height="24px"
                          alt="Minha conta"
                        />

                        <label className={cx("", styles.accountLabel)}>
                          {translate("publicData")}
                        </label>
                      </div>
                      <div className={styles.contentCard}>
                        <div
                          className={styles.sectionContainer}
                          /* onClick={() => switchRender("username")} */
                        >
                          <div className={styles.containerLabels}>
                            <label className={cx(styles.labelCard)}>
                              {translate("username")}
                            </label>
                            <label className={styles.labelCardInfo}>
                              {data?.username || ""}
                            </label>
                          </div>
                          <Icon name="Down" className={styles.iconDown} />
                        </div>
                        <div
                          className={styles.sectionContainer}
                          onClick={() => switchRender("nickname")}
                        >
                          <div className={styles.containerLabels}>
                            <label className={cx(styles.labelCard)}>
                              {translate("changeNickname")}
                            </label>

                            <label
                              className={
                                data.nickname === "" || null
                                  ? styles.labelInfoEmpty
                                  : styles.labelCardInfo
                              }
                            >
                              {data.nickname}
                            </label>
                          </div>
                          <Icon name="Down" className={styles.iconDown} />
                        </div>
                        <div
                          className={styles.sectionContainer}
                          onClick={() => switchRender("email")}
                        >
                          <div className={styles.containerLabels}>
                            <label className={cx(styles.labelCard)}>
                              {translate("switchEmail")}
                            </label>
                            <label className={cx(styles.labelCardInfo)}>
                              {data.email}
                            </label>
                          </div>
                          <Icon name="Down" className={styles.iconDown} />
                        </div>
                      </div>
                    </Card>
                  </div>
                </Col>
                <Col
                  order={isTablet || isDesktopMini ? 2 : 3}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                >
                  <div className={styles.sepatorCard}></div>
                  <div className={cx(styles.gridWidth)}>
                    <Card
                      className={cx(
                        "anonimatta-card-subscriber",
                        styles.colorCard
                      )}
                    >
                      <div className="flex items-center">
                        <img
                          className="pointer"
                          src={require("@images/icons/my-account.svg")}
                          width="24px"
                          height="24px"
                          alt="Minha conta"
                        />

                        <label className={cx("", styles.accountLabel)}>
                          {translate("myAccount")}
                        </label>
                      </div>

                      <div className={styles.contentCard}>
                        <div
                          className={styles.sectionContainer}
                          onClick={() => switchRender("password")}
                        >
                          <div className={styles.containerLabels}>
                            <label
                              className={cx(
                                "f16 fw5 lh-copy mine-shaft pointer",
                                styles.labelCard
                              )}
                            >
                              {translate("password")}
                            </label>
                            <label
                              className={cx(
                                "f12 fw5 tr lh-copy mr12 pointer",
                                styles.labelCardInfo
                              )}
                            >
                              *********
                            </label>
                          </div>
                          <Icon name="Down" className={styles.iconDown} />
                        </div>

                        <div
                          className={styles.sectionContainer}
                          onClick={() => switchRender("phone")}
                        >
                          <div className={styles.containerLabels}>
                            <label
                              className={cx(
                                "f16 fw5 lh-copy mine-shaft pointer",
                                styles.labelCard
                              )}
                            >
                              {translate("phone")}
                            </label>

                            <label
                              className={cx(
                                "f12 fw5 tr lh-copy mr12 pointer",
                                styles.labelCardInfo
                              )}
                            >
                              {newPhone}
                            </label>
                          </div>
                          <Icon name="Down" className={styles.iconDown} />
                        </div>

                        <div
                          className={styles.sectionContainer}
                          onClick={() => switchRender("name")}
                        >
                          <div className={styles.containerLabels}>
                            <label
                              className={cx(
                                "f16 fw5 lh-copy mine-shaft pointer",
                                styles.labelCard
                              )}
                            >
                              {translate("name")}
                            </label>
                            <label
                              className={cx(
                                "f12 fw5 tr lh-copy mr12 pointer",
                                styles.labelCardInfo
                              )}
                            >
                              {data.name}
                            </label>
                          </div>

                          <Icon name="Down" className={styles.iconDown} />
                        </div>
                      </div>
                    </Card>
                  </div>
                </Col>
                <Col
                  order={4}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={24}
                  className={styles.containerTerms}
                >
                  <div className={styles.margin}>
                    <div className={styles.sepatorCard}></div>
                    <div className={styles.cardTerms}>
                      <div clasName={styles.termslinks}>
                        <img
                          className={styles.icon}
                          src={require("@images/icons/document.svg")}
                          width="14px"
                          height="14px"
                          alt="Plano"
                        />
                        <a
                          href={terms}
                          className={styles.termsLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {translate("term")}
                        </a>
                        <span
                          style={{ marginLeft: 3, marginRight: 3 }}
                          className={styles.terms}
                        >
                          {translate("and")}
                        </span>
                        <a
                          href={privacy}
                          className={styles.termsLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {translate("privacy")}
                        </a>
                      </div>
                      <span className={styles.separatorTerms}></span>

                      <div
                        className={styles.logout}
                        onClick={handleClickLogout}
                      >
                        <img
                          className={styles.icon}
                          src={require("@images/icons/logout.svg")}
                          width="14px"
                          height="14px"
                          alt="Plano"
                        />{" "}
                        Sair do aplicativo{" "}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </TranslateWrapper>

      {errors.length ? (
        <CustomModalErrors
          errors={errors}
          setErrors={setErrors}
          title={"inactiveSubscriptionErrorTitle"}
        />
      ) : null}

      <MyAccountModal
        visible={visible}
        handleClose={handleClose}
        className="mc forgotPassword"
      >
        <TranslateWrapper>{modalContent}</TranslateWrapper>
      </MyAccountModal>

      <ChangeProfilePhoto
        visible={changePhotoVisible}
        setVisible={setChangePhotoVisible}
        srcProfile={srcProfile}
        data={data}
        handleGetDataSub={handleGetDataSub}
        setSuccessMessage={setSuccessMessageAll}
        isSubscriber
      />

      <Modal
        className="managePlanModalContext"
        footer={false}
        visible={managePlanVisible}
        onCancel={() => setManagePlanVisible(false)}
        closeIcon={
          <button className={styles.buttonClose}>
            <Icon name="CloseModal" />
          </button>
        }
      >
        <TranslateWrapper>
          <ManagePlan
            handleVisible={handleManagePlanVisible}
            data={dataSub}
            setSuccessMessage={setSuccessMessageAll}
            setSuccessMessagePlanCanceled={setSuccessMessagePlanCanceled}
            update={handleGetDataSub}
            validateDate={validateDate}
            suspensePlan={suspensePlan}
            planName={planName}
            planValue={planValue}
            amountPlan={amountPlan}
            closeModal={() => setManagePlanVisible(false)}
          />
        </TranslateWrapper>
      </Modal>

      {successMessagePhoneAndPassword ? (
        <SuccessModal
          visible={!!successMessagePhoneAndPassword}
          description={successMessagePhoneAndPassword}
          handleClose={() => {
            setSuccessMessagePhoneAndPassword(null)
            logout()
          }}
          buttonText={translate("continue")}
        />
      ) : null}

      {successMessageAll ? (
        <SuccessModal
          visible={!!successMessageAll}
          description={successMessageAll}
          handleClose={() => setSuccessMessageAll(null)}
          buttonText={translate("continue")}
        />
      ) : null}
      {successMessagePlanCanceled ? (
        <SuccessModalStylized
          visible={!!successMessageAll}
          description={successMessageAll}
          handleClose={() => setSuccessMessagePlanCanceled(null)}
          buttonText={translate("continue")}
          optionModal="planCanceled"
        />
      ) : null}
    </>
  )
}

export default SubscriberAccount
