import { DownOutlined, RightOutlined } from "@ant-design/icons"
import { CustomModalErrors } from "@components/CustomModalErrors"
import { Icon } from "@components/Icons"
import TranslateWrapper from "@components/TranslateWrapper"
import { useStores } from "@hooks/use-stores"
import UserPlan from "@modules/Registration/SubscriberRegistration/UserPlan.js"
import translate from "@src/i18n"
import { getPriceFromPlan } from "@utils/plans"
import { getActivePlans } from "@utils/plans"
import { Button, Divider, Modal } from "antd"
import cx from "classnames"
import _get from "lodash/get"
import _map from "lodash/map"
import React, { useCallback, useEffect, useState } from "react"
import { useMedia } from "react-use-media"

import ButtomCloseModal from "./components/ButtonCloseModal"
import CancelPlan from "./components/CancelPlan"
import ChangePaymentMode from "./components/ChangePaymentMode"
import HistoryPlan from "./components/HistoryPlan"
import PaymentPlan from "./components/PaymentPlan"
import styles from "./style.module.less"

const ManagePlan = (props) => {
  const {
    handleVisible,
    data,
    setSuccessMessage,
    update,
    validateDate,
    suspensePlan,
    closeModal,
    planName,
    amountPlan,
    planValue,
    setSuccessMessagePlanCanceled,
  } = props

  const [historyVisible, setHistoryVisible] = useState(false)
  const [cancelPlanVisible, setCancelPlanVisible] = useState(false)
  const [isCancelable, setIsCancelable] = useState(false)
  const [isPaymentOverdue, setIsPaymentOverdue] = useState(false)
  const [option, setOption] = useState("")
  const [visible, setVisible] = useState(false)
  const [purchaseVisible, setPurchaseVisible] = useState(false)
  const [paymentModalVisible, setPaymentModalVisible] = useState(false)
  const [isChangePaymentMode, setIsChangePaymentMode] = useState(false)
  const [plans, setPlans] = useState([])
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [errors, setErrors] = useState([])
  const [pixInvoices, setPixInvoices] = useState([])
  const [cardInvoices, setCardInvoices] = useState([])
  const [anonimattaSubscription, setAnonimattaSubscription] = useState(null)
  const [loading, setLoading] = useState(false)
  const [userPlanModalVisible, setUserPlanModalVisible] = useState(false)
  const [classNameModal, setClassNameModal] = useState("")

  const { registrationSubscriber, authSite } = useStores()

  const {
    listPlans,
    changePlans,
    cancelPlan,
    listSubscriberOrders,
    listSubscriptionInvoices,
  } = registrationSubscriber

  const { user } = authSite

  const isMobile = useMedia({ maxWidth: 767 })
  const isDesktop = useMedia({ minWidth: 768 })

  const subscriptionStatus = {
    ACTIVE: "Ativa",
    OVERDUE: "Não pago",
    CANCELING: "Em cancelamento",
  }

  const paymentType = {
    CREDIT_CARD: "Cartão de crédito",
    PIX: "Pix",
    BOLETO: "Boleto",
  }
  useEffect(() => {
    handleGetAllPlans()
  }, [selectedPlan])

  useEffect(() => {
    handleGetPixPaymentHistory()
    handleGetCardPaymentHistory()
    if (paymentType[anonimattaSubscription?.paymentType] !== "Pix") {
      setIsCancelable(true)
    } else {
      setIsCancelable(false)
    }

    if (
      anonimattaSubscription?.statusSubscription === "OVERDUE" &&
      (anonimattaSubscription?.statusInvoice === 5 ||
        anonimattaSubscription.statusInvoice === 4)
    ) {
      setIsPaymentOverdue(true)
    } else {
      setIsPaymentOverdue(false)
    }
  }, [anonimattaSubscription])

  useEffect(() => {
    if (selectedPlan) {
      switchRender("purchasePaymentFinish")
    }
  }, [selectedPlan])

  useEffect(() => {
    if (data?.subscriptions) {
      const subscription = data?.subscriptions[0]
      setAnonimattaSubscription(subscription)
    }
  }, [data])

  const handleGetAllPlans = useCallback(async () => {
    const response = await listPlans()
    const plans = _get(response, "plans", [])
    const activePlans = getActivePlans(plans)

    setPlans(activePlans)
  })

  const handleChangePlans = async (code) => {
    const subscriptionCode = anonimattaSubscription.subscriptionCode
    const newCode = { plan: { code: code } }
    const response = await changePlans(subscriptionCode, newCode)

    if (response === "undefined") {
      setErrors(translate("errorChangePlan"))
    }

    const errorMessage = _get(response, "errors", "")

    if (errorMessage) {
      setErrors(errorMessage)
      return
    }
  }

  const handleCodePlan = async (code) => {
    try {
      await handleChangePlans(code)
      setSuccessMessage(translate("planChanged"))
      setVisible(false)
      update()
    } catch (error) {
      console.error(error)
    }
  }

  const handleCodePlanPurschase = (plan) => {
    try {
      setSelectedPlan(plan)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCancelPlan = async () => {
    setLoading(true)
    const subscriptionCode = anonimattaSubscription.subscriptionCode

    const response = await cancelPlan(subscriptionCode)

    const errorMessage = _get(response, "errors", "")

    if (errorMessage) {
      setLoading(false)
      handleCancelPlanVisible()
      closeModal()
      setErrors(errorMessage)
      return
    }

    //setSuccessMessage(translate("planCanceled"))
    setLoading(false)
    setCancelPlanVisible(false)
    update()
  }

  const handleGetCardPaymentHistory = async () => {
    try {
      const { invoices } = await listSubscriptionInvoices(
        anonimattaSubscription.subscriptionCode
      )
      setCardInvoices(invoices)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetPixPaymentHistory = async () => {
    try {
      const pixInvoices = await listSubscriberOrders(user.id, { type: "PIX" })
      setPixInvoices(pixInvoices)
    } catch (error) {
      console.log(error)
    }
  }

  const handlePaymentVisible = () => {
    setHistoryVisible(!historyVisible)
    handleVisible()
  }

  const handleCancelPlanVisible = () => {
    setCancelPlanVisible(!cancelPlanVisible)
    handleVisible()
  }
  const isCancelling =
    anonimattaSubscription?.statusSubscription === "CANCELING"

  const renderTitle = () => {
    return (
      <div className={cx("user__plan__title", styles.user__plan__payment)}>
        <div
          className={cx(
            "mt48 mb32 title",
            styles.user__plan__payment__container
          )}
        >
          <div className={cx("title", styles.user__plan__payment__title)}>
            <span>{translate("planUserTitle")}</span>
            <p>{translate("paymentWithCardOrPix")}</p>
          </div>
        </div>
      </div>
    )
  }

  const switchRender = (option) => {
    switch (option) {
      case "changePlan":
        setOption(
          <>
            {renderTitle()}
            <UserPlan
              plans={plans}
              isMobile={isMobile}
              isDesktop={isDesktop}
              setCode={handleCodePlan}
              isChangePlan
              isUserPlanModal
            />
          </>
        )

        setClassNameModal("userPlanContext")
        setVisible(true)
        return
      case "retryPayment":
        setOption(
          <ChangePaymentMode
            data={data}
            user={user}
            planName={planName}
            amountPlan={amountPlan}
            setSuccessMessage={setSuccessMessage}
            closeModal={() => {
              setVisible(false)
              update()
            }}
            isRetryPayment
          />
        )
        setClassNameModal("changePlanContextRetry")
        setVisible(true)
        return

      case "purchasePlan":
        setOption(
          <div className="mb32">
            {renderTitle()}
            <UserPlan
              plans={plans}
              isMobile={isMobile}
              isDesktop={isDesktop}
              registerFormValues={data}
              setCodePurchase={handleCodePlanPurschase}
              isChangePlan
              isPurchasePlan
              isUserPlanModal
            />
          </div>
        )

        setVisible(true)
        setClassNameModal("userPlanContext")
        return

      case "purchasePaymentFinish":
        setOption(
          <ChangePaymentMode
            data={data}
            user={user}
            selectedPlan={selectedPlan}
            switchRender={switchRender}
            setSuccessMessage={setSuccessMessage}
            setVisible={setVisible}
            update={update}
            isPurchasePlan
          />
        )
        setVisible(true)
        setClassNameModal("purchaseFinish purchaseMobileModal")
        return

      case "changePaymentMode":
        setOption(
          <ChangePaymentMode
            data={data}
            user={user}
            planName={planName}
            amountPlan={amountPlan}
            handleCancelPlanVisible={handleCancelPlanVisible}
            setSuccessMessage={setSuccessMessage}
            closeModal={closeModal}
            isPaymentPlan
          />
        )
        setIsChangePaymentMode(true)
        setVisible(true)
        setClassNameModal("paymentContext")
        return

      default:
        return
    }
  }

  const modalRender = () => {
    return (
      <Modal
        className={classNameModal ? classNameModal : "changePlanContext"}
        visible={visible}
        footer={false}
        onCancel={() => setVisible(false)}
        closeIcon={<ButtomCloseModal />}
      >
        <TranslateWrapper>
          <div className={cx("subscriber-registration")}>{option}</div>
        </TranslateWrapper>
      </Modal>
    )
  }

  return !suspensePlan ? (
    <>
      <div
        className={cx(
          "pt40 pb40 flex flex-column h-100",
          styles.managePlanContainer
        )}
      >
        <div className={cx("", styles.containerTitle)}>
          <span className={cx("mb8", styles.title)}>
            {translate("subscriptionInfo")}
          </span>
        </div>

        <div className={cx("mb16", styles.containerPlanData)}>
          <div className={cx("flex flex-column ", styles.planHistoryPadding)}>
            <div className="flex flex-column mb16">
              <span className={styles.cardLabel}>Seu plano</span>
              <p className={styles.cardInfo}>
                {planName}: R$
                {getPriceFromPlan(planValue).toFixed(2)}
              </p>
            </div>

            <div className="flex flex-column mb16">
              <span className={styles.cardLabel}>
                {translate("switchPaymentMethod")}
                {/* :{" "} */}
              </span>
              <p className={styles.cardInfo}>
                {paymentType[anonimattaSubscription?.paymentType]}
              </p>
            </div>

            <div
              className={cx(
                "flex flex-row justify-between ",
                styles.blockButton
              )}
            >
              <div className="flex flex-column " style={{ marginTop: 4 }}>
                <span className={styles.cardLabel}>
                  {translate("subscriptionStatus")}
                  {/* :{" "} */}
                </span>
                <p className={styles.cardInfo}>
                  {
                    subscriptionStatus[
                      anonimattaSubscription?.statusSubscription
                    ]
                  }
                </p>
              </div>

              {isCancelable && (
                <button
                  className={
                    isCancelling
                      ? styles.buttonCancellingPlan
                      : styles.buttonActivePlan
                  }
                  onClick={handleCancelPlanVisible}
                  disabled={isCancelling}
                >
                  {translate("cancel")}
                </button>
              )}
            </div>
          </div>
        </div>
        {anonimattaSubscription?.statusSubscription === "CANCELING" && (
          <div className={cx("tc", styles.blockCancel)}>
            <div>
              <p className={cx("mb8", styles.cardInfo)}>
                {translate("subscriptionCanceled")}
              </p>
              <div className={cx("", styles.cardDescription)}>
                {translate("waitingCancel1")}{" "}
                <span className={styles.finalDate}>{validateDate}</span>
                {translate("waitingCancel2")}
              </div>
            </div>
          </div>
        )}
        {isPaymentOverdue && (
          <div className={cx("tc", styles.blockCancel)}>
            <div
              className={cx(
                "flex flex-row justify-between ph16 pointer",
                styles.containerPadding
              )}
              onClick={() => {
                switchRender("retryPayment")
              }}
            >
              <span className={styles.cardInfo}>
                {translate("retryPayment")}
              </span>
              <Icon name="Down" className={styles.iconDown} />
            </div>
          </div>
        )}
        {/*   { anonimattaSubscription?.statusSubscription !== "CANCELING"  && (
          <div className={cx("tc", styles.blockCancel)}>
            <div
              className={cx(
                "flex flex-row justify-between ph16 pointer",
                styles.containerPadding
              )}
              onClick={() => {
                switchRender("changePlan")
              }}
            >
              <span className={styles.cardInfo}>{translate("managePlan")}</span>
              <Icon name="Down" className={styles.iconDown} />
            </div>

            <div
              className={cx(
                "flex flex-row justify-between ph16 pointer",
                styles.containerPadding
              )}
              onClick={() => switchRender("changePaymentMode")}
            >
              <span className={styles.cardInfo}>
                {translate("switchPaymentMethod")}
              </span>
              <Icon name="Down" className={styles.iconDown} />
            </div>
          </div>
        )  }     */}
        <HistoryPlan cardInvoices={cardInvoices} pixInvoices={pixInvoices} />

        {pixInvoices.length > 3 && (
          <a
            onClick={handlePaymentVisible}
            className="kimberly underline f14 normal mt32"
          >
            {translate("seeAll")} <DownOutlined className="kimberly f14" />
          </a>
        )}
      </div>
      <Modal
        className="managePlanContext"
        visible={historyVisible}
        footer={false}
        onCancel={handlePaymentVisible}
        closeIcon={<ButtomCloseModal />}
      >
        <HistoryPlan
          handlePaymentVisible={handlePaymentVisible}
          invoices={pixInvoices}
        />
      </Modal>
      <Modal
        className="cancelPlanModalContext"
        visible={cancelPlanVisible}
        footer={false}
        onCancel={handleCancelPlanVisible}
        closeIcon={<ButtomCloseModal />}
      >
        <CancelPlan
          handleCancelPlanVisible={handleCancelPlanVisible}
          cancelPlan={handleCancelPlan}
          validateDate={validateDate}
          instantCancel={isPaymentOverdue}
          loading={loading}
        />
      </Modal>
      {modalRender()}

      {errors.length ? (
        <CustomModalErrors errors={errors} setErrors={setErrors} />
      ) : null}
    </>
  ) : (
    <>
      <div
        className={cx(
          "pt40 pb40 flex flex-column h-100",
          styles.managePlanContainer
        )}
      >
        <div className={cx("", styles.containerTitle, styles.paddingTitle)}>
          <span className={cx("mb8", styles.title)}>
            {translate("newSubscription")}
          </span>
        </div>
        <div className={cx("tc", styles.blockNewPlan)}>
          <div
            className={cx(
              "flex flex-row justify-between ph16 pointer",
              styles.containerPadding
            )}
            onClick={() => {
              switchRender("purchasePlan")
            }}
          >
            <span className={styles.cardInfo}>{translate("purchasePlan")}</span>
            <Icon name="Down" className={styles.iconDown} />
          </div>
        </div>
        <div className={styles.noPlanIcon}>
          <img
            className="pointer"
            src={require("@images/icons/noPlan.svg")}
            alt="Sem planos ativos"
          />
        </div>
      </div>

      <Modal
        className={classNameModal ? classNameModal : "changePlanContext"}
        /*  className={cx("changePlanContext", {
          userPlanModal: option?.type?.name === "UserPlan",
        })} */
        visible={visible}
        footer={false}
        onCancel={() => setVisible(false)}
        closeIcon={<ButtomCloseModal />}
      >
        <TranslateWrapper>
          <div className={cx("subscriber-registration")}>{option}</div>
        </TranslateWrapper>
      </Modal>

      {errors.length ? (
        <CustomModalErrors errors={errors} setErrors={setErrors} />
      ) : null}
    </>
  )
}

export default ManagePlan
