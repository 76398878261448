import translate from "@src/i18n"
import { Button, Col, Form, Input, Row, Select } from "antd"
import _get from "lodash/get"
import _map from "lodash/map"
import React, { useEffect } from "react"
import InputMask from "react-input-mask"
import { useIntl } from "react-intl"
import styles from "../style.module.less"

const SubscriberAddress = ({
  states,
  cities,
  handleGetAllCities,
  handleSearchAddressViaCep,
  addressViaCep,
  addressUpdate,
}) => {
  const intl = useIntl()
  const [form] = Form.useForm()

  const VALIDATE_MESSAGES = {
    required: intl.formatMessage({ id: "requiredInput" }),
    string: {
      min: intl.formatMessage({ id: "messageErrorPassword" }),
    },
  }

  useEffect(() => {
    if (addressViaCep && addressViaCep.error) {
      clearFields()
      return
    }

    if (addressViaCep && !addressViaCep.error) {
      const street = _get(addressViaCep, "logradouro", "")
      const district = _get(addressViaCep, "bairro", "")
      const city = _get(addressViaCep, "localidade", "")
      const state = _get(addressViaCep, "uf", "")

      form.setFieldsValue({ street, district, city, state })
    }
  }, [addressViaCep])

  const handleChangeState = (value) => {
    handleGetAllCities(value)
  }

  const handleBlurZipCode = (event) => {
    const value = event.target.value

    if (value.length < 9) return

    handleSearchAddressViaCep(value)
  }

  const validateZipCode = () => ({
    validator(rule, value) {
      if (value) {
        if (value.length < 9 || (addressViaCep && addressViaCep.error)) {
          return Promise.reject(intl.formatMessage({ id: "errorZipCode" }))
        }
      }
      return Promise.resolve()
    },
  })

  const clearFields = () => {
    form.setFieldsValue({
      street: "",
      complement: "",
      district: "",
      city: "",
      state: "",
    })
  }

  const handleUpdateAdress = (props) => {
    addressUpdate(props)
  }

  const zipCode = _get(addressViaCep, "cep", "")

  return (
    <Form
      form={form}
      layout="vertical"
      validateMessages={VALIDATE_MESSAGES}
      onFinish={handleUpdateAdress}
      autoComplete="off"
      initialValues={{ country: "BRA" }}
    >
      <div className="flex flex-row justify-center w-100">
        <Col span={12} xs={24} md={24}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Col span={12} xs={24} md={12}>
              <Form.Item
                label={translate("zipcode")}
                name="zipcode"
                rules={[{ required: true }, validateZipCode]}
              >
                <InputMask
                  mask="99999-999"
                  maskChar={null}
                  /*  placeholder={intl.formatMessage({
                    id: "placeholderZicode",
                  })} */
                  className="input-general"
                  onBlur={handleBlurZipCode}
                >
                  {(inputProps) => <Input {...inputProps} />}
                </InputMask>
              </Form.Item>
            </Col>
            <Col span={12} xs={24} md={12}>
              <Form.Item
                label={translate("street")}
                name="street"
                rules={[{ required: true }]}
              >
                <Input
                  className="input-general"
                  /*  placeholder={intl.formatMessage({ id: "typeStreet" })} */
                />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} md={12}>
              <Form.Item
                label={translate("number")}
                name="number"
                rules={[{ required: true }]}
              >
                <InputMask
                  mask="99999999"
                  maskChar={null}
                  /*  placeholder={intl.formatMessage({
                    id: "typeNumber",
                  })} */
                  className="input-general"
                >
                  {(inputProps) => <Input {...inputProps} />}
                </InputMask>
              </Form.Item>
            </Col>
            <Col span={12} xs={24} md={12}>
              <Form.Item label={translate("complement")} name="complement">
                <Input
                  className="input-general"
                  /*  placeholder={intl.formatMessage({ id: "typeComplement" })} */
                />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} md={12}>
              <Form.Item
                label={translate("district")}
                name="district"
                rules={[{ required: true }]}
              >
                <Input
                  className="input-general"
                  /* placeholder={intl.formatMessage({ id: "typeDistrict" })} */
                />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} md={12}>
              <Form.Item
                label={translate("state")}
                name="state"
                rules={[{ type: "string", required: true }]}
              >
                <Select
                  className="select-document"
                  showSearch
                  onChange={handleChangeState}
                  disabled={zipCode}
                  /* placeholder={intl.formatMessage({
                    id: "selectStateBrazil",
                  })} */
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {_map(states, (item) => (
                    <Select.Option key={item.id} value={item.sigla}>
                      {item.sigla}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} xs={24} md={12}>
              <Form.Item
                label={translate("city")}
                name="city"
                rules={[{ type: "string", required: true }]}
              >
                <Select
                  className="select-document"
                  showSearch
                  disabled={zipCode || !form.getFieldValue("state")}
                  /* placeholder={intl.formatMessage({ id: "selectCityBrazil" })} */
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {_map(cities, (item) => (
                    <Select.Option key={item.id} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} xs={24} md={12}>
              <Form.Item
                label={translate("country")}
                name="country"
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  className="select-document"
                  /* placeholder={intl.formatMessage({ id: "selectCountry" })} */
                  disabled
                >
                  <Select.Option value="BRA">
                    {intl.formatMessage({ id: "brazil" })}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {zipCode && (
            <Row className={(styles.centerSubs, styles.buttonMarginTopSubs)}>
              <Button
                type="primary"
                className="continue-registration"
                htmlType="submit"
              >
                {translate("continue")}
              </Button>
            </Row>
          )}
        </Col>
      </div>
    </Form>
  )
}

export default SubscriberAddress
