import { CustomModalErrors } from "@components/CustomModalErrors"
import { Icon } from "@components/Icons"
import PixExpirationTimer from "@components/PixExpirationTimer"
import SuccessAnimation from "@components/SuccessAnimation"
import SubscriberAddress from "./SubscriberAddress"
import Loading from "@components/Loading"
import { useStores } from "@hooks/use-stores"
import {
  createSignatureAndSubscriberWirecardAdapter,
  errorsAdapter,
  PaymentMethodEnum,
} from "@modules/Registration/SubscriberRegistration/adapters/registration.js"
import styles from "@modules/Registration/SubscriberRegistration/style.module.less"
import translate from "@src/i18n"
import { getExpirationBill } from "@utils/date"
import { formatNumberToCurrency } from "@utils/numbers"
import { getPeriodFromPlan, getPriceFromPlan } from "@utils/plans"
import { getOnlyDigits } from "@utils/strings"
import { getTask, startTask, stopAllPixTasks } from "@utils/taskSchedulers"
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Tabs,
  Tooltip,
} from "antd"
import cx from "classnames"
import { Link, navigate } from "gatsby"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import { MoipValidator } from "moip-sdk-js"
import moment from "moment"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import InputMask from "react-input-mask"
import { useIntl } from "react-intl"
import { useMedia } from "react-use-media"

import localStyles from "../style.module.less"

const TabItem = ({ actualTab, tab }) => {
  if (actualTab === "card") {
    return (
      <div className="flex items-center w-100">
        <Radio value="1" checked={tab === "1"} />
        <span>{translate("creditCard")}</span>
      </div>
    )
  }

  return (
    <div className="flex items-center w-100">
      <Radio value="2" checked={tab === "2"} />
      <span>{translate("pix")}</span>
    </div>
  )
}

const SelectedPlan = ({
  selectedPlan,
  switchRender,
  planName,
  amountPlan,
  isPaymentPlan,
  typeDiscount,
  discount,
  codeCoupon,
}) => {
  console.log("typeDiscount", typeDiscount)

  if (!isPaymentPlan) {
    const { interval, amount } = selectedPlan

    const switchPlan = (e) => {
      e.preventDefault()
      switchRender("purchasePlan")
    }

    let price = null

    if (typeDiscount === "AMOUNT") {
      price = getPriceFromPlan(amount) - getPriceFromPlan(discount)
    } else if (typeDiscount === "PERCENT") {
      price =
        getPriceFromPlan(amount) -
        getPriceFromPlan(amount) * (getPriceFromPlan(discount) / 100)
    } else {
      price = getPriceFromPlan(amount || amountPlan)
    }
    const period = getPeriodFromPlan(interval || planName)

    return (
      <div
        className={cx(
          "flex flex-row justify-between bg-french-rose pa16 br3",
          styles.containerPriceCard
        )}
      >
        <div
          className={cx(
            "flex flex-row justify-center items-center",
            styles.pricePlan
          )}
        >
          <img className="" src={require("@images/icons/checkPrice.svg")} />
          <div className="flex flex-column">
            <span className={cx("white", styles.price)}>
              {formatNumberToCurrency(price)}
            </span>
            <span className=" white">Plano {translate(period)}</span>
          </div>
        </div>
        <Link
          to="#"
          className={cx(" white ", styles.buttonSwitchPlan)}
          onClick={switchPlan}
        >
          {translate("change")}
        </Link>
      </div>
    )
  } else {
    const period = planName
    const price = getPriceFromPlan(amountPlan)

    return (
      <div
        className={cx(
          "flex flex-row justify-between bg-french-rose pa16 br3",
          styles.containerPriceCard
        )}
      >
        <div
          className={cx(
            "flex flex-row justify-center items-center",
            styles.pricePlan
          )}
        >
          <img className="" src={require("@images/icons/checkPrice.svg")} />
          <div className="flex flex-column">
            <span className={cx("white", styles.price)}>
              {formatNumberToCurrency(price)}
            </span>
            <span className=" white">Plano {translate(period)}</span>
          </div>
        </div>
        <Link
          to="#"
          className={cx(" white ", styles.buttonSwitchPlan)}
          /*  onClick={switchPlan} */
        >
          {translate("change")}
        </Link>
      </div>
    )
  }
}

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const ChangePaymentMode = (props) => {
  const {
    data,
    user,
    selectedPlan,
    switchRender,
    isPaymentPlan,
    isRetryPayment,
    setSuccessMessage,
    setVisible,
    update,
    planName,
    amountPlan,
    paymentMethods,
    closeModal,
  } = props
  console.log("isPaymentPlan", isPaymentPlan)
  const { registrationSubscriber, registrationAffiliated } = useStores()
  const {
    createSignatureWirecard,
    createSignatureAndSubscriberWirecard,
    validate,
    changePaymentMode,
    generateSubscribedPixCode,
    verifyPixPayment,
    retryPayment,
    updateCreditCard,
    generateCouponDiscount,
    searchAddressViaCep,
    updateAddressSubscriberAnonimatta,
    getAddressSubs,
  } = registrationSubscriber

  const { getAllCities } = registrationAffiliated

  const [form] = Form.useForm()
  const [pixPayment, setPixPayment] = useState()
  const [pixTimer, setPixTimer] = useState(0)
  const [tab, setTab] = useState("1")
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(false)
  const [checked, setChecked] = useState(false)
  const [codeCoupon, setCodeCoupon] = useState("")
  const [typeCoupon, setTypeCoupon] = useState(null)
  const [valueCoupon, setValueCoupon] = useState(0)
  const [loadingCoupon, setLoadingCoupon] = useState("")
  const [loadingAddress, setLoadingAddress] = useState(false)
  const [addressFormValues, setAddressFormValues] = useState(false)
  const [haveAddress, setHaveAddress] = useState(false)
  const [addressViaCep, setAddressViaCep] = useState([])
  const [cities, setCities] = useState([])

  const prevPix = usePrevious(pixPayment)

  const intl = useIntl()

  const isMobile = useMedia({ maxWidth: 767 })
  const isDesktop = useMedia({ minWidth: 768 })

  const terms = process.env.AWS_TERMS
  const privacy = process.env.AWS_PRIVACY

  const VALIDATE_MESSAGES = {
    required: intl.formatMessage({ id: "requiredInput" }),
  }

  useEffect(() => {
    retrieveAddressSub()
  }, [])

  const retrieveAddressSub = useCallback(async () => {
    setLoadingAddress(true)

    const id = _get(user, "id")
    const response = await getAddressSubs(id)

    if (
      _get(response, "zipcode") &&
      _get(response, "street") &&
      _get(response, "number")
    ) {
      setAddressFormValues(response)
      setHaveAddress(true)
      setLoadingAddress(false)
    } else {
      setHaveAddress(false)
      setLoadingAddress(false)
    }
  }, [])

  const handleTabSelected = (key) => {
    setTab(key)
  }

  const validateCardNumber = () => ({
    validator(rule, value) {
      if (value) {
        const onlyDigitsValue = value.replace(/\D/g, "")

        if (!MoipValidator.isValidNumber(onlyDigitsValue)) {
          return Promise.reject(intl.formatMessage({ id: "cardNumberError" }))
        }
      }

      return Promise.resolve()
    },
  })

  const validateNameCard = () => ({
    validator(rule, value) {
      if (value) {
        const regexNameOnlyCharacters = new RegExp(
          "[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
        )

        if (!regexNameOnlyCharacters.test(value)) {
          return Promise.reject(
            intl.formatMessage({ id: "errorOnlyCharacters" })
          )
        }
      }

      return Promise.resolve()
    },
  })

  const validateDueDate = () => ({
    validator(rule, value) {
      if (value) {
        const month = value.split("/")[0]
        const year = value.split("/")[1]

        if (!MoipValidator.isExpiryDateValid(month, year)) {
          return Promise.reject(intl.formatMessage({ id: "invalidDate" }))
        }
      }

      return Promise.resolve()
    },
  })

  const validateCodeCard = () => ({
    validator(rule, value) {
      if (value) {
        const creditCardNumber = form.getFieldValue("cardNumber")

        if (!creditCardNumber) {
          return Promise.reject(
            intl.formatMessage({ id: "errorCardCodeNumberCreditCard" })
          )
        }

        if (!MoipValidator.isSecurityCodeValid(creditCardNumber, value)) {
          return Promise.reject(intl.formatMessage({ id: "errorCardCode" }))
        }
      }

      return Promise.resolve()
    },
  })

  const validateDocumentCPF = () => ({
    async validator(rule, value) {
      if (value) {
        const regexCPF = new RegExp(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/)

        if (!regexCPF.test(value)) {
          return Promise.reject(
            intl.formatMessage({ id: "errorDocumentNumber" })
          )
        }
      }

      return Promise.resolve()
    },
  })

  const handleCreateSignatureWirecard = async ({
    paymentMethod,
    values = {},
  }) => {
    // Montagem do payload para o adapter para garantir a consistencia dos códigos (assiantura e customer)
    const selectedPlanPayload = {
      code: selectedPlan.code,
      name: selectedPlan.description,
      amount: selectedPlan.amount,
    }

    const paymentFormValues = {
      paymentMethod,
      ...values,
    }

    const address = {
      city: _get(user, "city") ? user.city : addressFormValues.city,
      complement: _get(user, "complement")
        ? user.complement
        : addressFormValues.complement,
      country: _get(user, "country") ? user.country : addressFormValues.country,
      district: _get(user, "district")
        ? user.district
        : addressFormValues.district,
      number: _get(user, "number") ? user.number : addressFormValues.number,
      state: _get(user, "state") ? user.state : addressFormValues.state,
      street: _get(user, "street") ? user.street : addressFormValues.street,
      zipcode: _get(user, "zipcode") ? user.zipcode : addressFormValues.zipcode,
    }

    const registerFormValues = {
      identityDocument: user.documentType,
      documentNumberCPF: user.documentNumber,
      documentNumberPassport: null,
      documentNumberOthers: null,
      fullName: user.name,
      phoneNumber: user.phoneNumber,
      dateOfBirth: moment(user.dateBirth).format("DD/MM/YYYY"),
      email: user.email,
      address,
    }

    const payload = {
      selectedPlan: selectedPlanPayload,
      registerFormValues,
      paymentFormValues,
      codeCoupon: valueCoupon ? codeCoupon : null,
    }

    const adaptedPayload = createSignatureAndSubscriberWirecardAdapter(payload)

    /*const payload = {
      code: `assinatura_${Date.now()}_${user.id}`,
      customer: {
        address: {
          city: user.city,
          complement: user.complement,
          country: user.country,
          district: user.district,
          number: user.number,
          state: user.state,
          street: user.street,
          zipcode: user.zipcode,
        },
        billing_info: {
          credit_card: {
            expiration_month: values.dueDate.split("/")[0],
            expiration_year: values.dueDate.split("/")[1],
            holder_name: values.nameCard,
            number: values.cardNumber.replaceAll(" ", ""),
          },
        },
        birthdate_day: moment(user.dateBirth).get("day"),
        birthdate_month: moment(user.dateBirth).get("month"),
        birthdate_year: moment(user.dateBirth).get("year"),
        code: `${Date.now()}_${user.id}`,
        cpf:
          user.documentType === "CPF"
            ? user.documentNumber.replace(/\D/g, "")
            : "",
        email: user.email,
        fullname: user.name,
        phone_area_code:
          user.phoneNumber.length === 13 ? user.phoneNumber.slice(2, 4) : "",
        phone_number:
          user.phoneNumber.length === 13 ? user.phoneNumber.slice(4) : "",
      },
      payment_method: paymentMethod,
      plan: {
        code: selectedPlan.code,
        name: selectedPlan.description,
      },
    }*/

    const res = await createSignatureAndSubscriberWirecard(adaptedPayload)

    return res
  }

  const handleCodeCoupon = (event) => {
    const value = event.target.value
    setCodeCoupon(value)
  }

  const handleChangePaymentMode = async (paymentMethod, values) => {
    const subscriptionCode = data.subscriptions.find(
      (subscription) => subscription.statusSubscription !== "CANCELED"
    ).subscriptionCode

    const paymentData = {
      payment_method: paymentMethod,
    }

    if (paymentMethod === "CREDIT_CARD") {
      const creditCard = {
        holder_name: values.nameCard,
        number: values.cardNumber.replaceAll(" ", ""),
        expiration_month: values.dueDate.split("/")[0],
        expiration_year: values.dueDate.split("/")[1],
      }
      paymentData.credit_card = creditCard
    }

    const response = await changePaymentMode(subscriptionCode, paymentData)

    const errorMessage = _get(response, "errors", "")

    if (errorMessage) {
      closeModal()
      setErrors(errorMessage)
      return
    }

    setSuccessMessage(translate("paymentMethodChanged"))
    update()
  }

  const handlePaymentMethod = async (paymentMethod, values) => {
    try {
      await handleChangePaymentMode(paymentMethod, values)
      setVisible(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleGenerateCouponDiscount = async () => {
    setLoadingCoupon(true)
    const response = await generateCouponDiscount(codeCoupon)

    if (response.status === "ACTIVE") {
      const typeDiscount = _get(response, "discount.type", "")
      const valueDiscount = _get(response, "discount.value", "")
      const codeCoupon = _get(response, "code", "")

      setTypeCoupon(typeDiscount)
      setValueCoupon(valueDiscount)
      setCodeCoupon(codeCoupon)
      setSuccessMessage(translate("couponApplied"))
    }

    if (response.status === "INACTIVE") {
      setLoadingCoupon(false)
      const adaptedErrors = errorsAdapter(translate("errorCoupon"))
      setErrors(adaptedErrors)
      return
    }

    if (response.statusCode === 404) {
      setLoadingCoupon(false)
      const adaptedErrors = errorsAdapter(translate("errorCoupon"))
      setErrors(adaptedErrors)
      return
    }

    const error = _get(response, "errors", "")

    if (error) {
      setLoadingCoupon(false)
      const adaptedErrors = errorsAdapter(translate("errorCoupon"))
      setErrors(adaptedErrors)
      return
    }
    setLoadingCoupon(false)
  }

  const handleRetryPayment = async (paymentMethod, values) => {
    setLoading(true)
    const lastInvoiceId = data.subscriptions[0].orderId
    const subscriptionCode = data.subscriptions[0].subscriptionCode
    const customerId = getOnlyDigits(user.documentNumber)

    const credit_card = {
      holder_name: values.nameCard,
      number: values.cardNumber.replaceAll(" ", ""),
      expiration_month: values.dueDate.split("/")[0],
      expiration_year: values.dueDate.split("/")[1],
    }

    const responseUpdateCard = await await updateCreditCard(customerId, {
      credit_card,
    })
    const updateErrorMessage = _get(responseUpdateCard, "errors", "")
    if (updateErrorMessage) {
      closeModal()
      setErrors(updateErrorMessage)
      setLoading(false)
      return
    }

    const responseRetry = await retryPayment(lastInvoiceId)
    const retryErrorMessage = _get(responseRetry, "errors", "")
    if (retryErrorMessage) {
      closeModal()
      setErrors(retryErrorMessage)
      setLoading(false)
      return
    }
    setLoading(false)
    closeModal()
    setSuccessMessage(translate("retryPaymentSuccess"))
    //setIsWaitingPayment(true)
  }

  const handleFinishForm = async (
    values, //dados do form pagamento

    paymentMethod = PaymentMethodEnum.CREDIT_CARD
  ) => {
    const errorCreditCardFormValues = !paymentMethod && _isEmpty(values)
    const errorSelectedPlan = _isEmpty(selectedPlan)

    if (errorCreditCardFormValues) {
      console.error(
        "Não foram encontrados todos os dados do form para pagamento via cartão de crédito!"
      )
      return
    }

    if (errorSelectedPlan) {
      console.error(
        "Não foram encontrados todos os dados necessários para continuar!"
      )
      return
    }

    setLoading(true)

    const resWirecard = await handleCreateSignatureWirecard({
      paymentMethod,
      values,
    })

    if (resWirecard.statusCode === 400) {
      setLoading(false)
      const adaptedErrors = errorsAdapter(resWirecard.message)
      setErrors(adaptedErrors)
      return
    }

    setLoading(false)

    if (paymentMethod === PaymentMethodEnum.BILLET) {
      navigate("/boleto/novo-boleto")
      return
    }
    setVisible(false)
    setSuccessMessage(translate("purchasedPlan"))
    update()
  }

  /* PIX */
  useEffect(() => {
    if (prevPix !== pixPayment) {
      if (pixPayment && !prevPix && pixPayment.status !== "CONCLUIDA") {
        startPaymentVerification()
      } else if (!pixPayment) {
        handleClearPix()
      }
    }
  }, [pixPayment])

  const handleClearPix = () => {
    stopAllPixTasks()
    setPixPayment(undefined)
    setPixTimer(0)
  }

  const handlePixPaymentApproved = (payment) => {
    setPixPayment(payment)
    stopAllPixTasks()
    setPixTimer(0)
    setLoading(false)
  }

  const startPaymentVerification = () => {
    let res = null
    const taskName = `pix-${pixPayment.txid}-${new Date().getTime()}`
    if (!getTask(taskName)) {
      startTask(
        taskName,
        async () => {
          res = await verifyPixPayment(pixPayment.txid)
          if (res && res.status === "CONCLUIDA") {
            handlePixPaymentApproved(res)
          }
        },
        10000
      )
    }
  }

  const handleCreatePixPayment = async () => {
    setLoading(true)
    const payment_data = {
      transaction_amount: selectedPlan.amount / 100,
      description: selectedPlan.description,
      payment_method_id: "pix",
      payer: {
        email: _get(user, "email", ""),
        first_name: _get(user, "name", ""),
        last_name: "",
        identification: {
          type: "CPF",
          number: _get(user, "documentNumber", ""),
        },
        address: {
          zip_code: _get(user, "zipcode")
            ? user.zipcode
            : addressFormValues.zipcode,
          street_name: _get(user, "street")
            ? user.street
            : addressFormValues.street,
          street_number: _get(user, "number")
            ? user.number
            : addressFormValues.number,
          neighborhood: _get(user, "district")
            ? user.district
            : addressFormValues.district,
          city: _get(user, "city") ? user.city : addressFormValues.city,
          federal_unit: _get(user, "state")
            ? user.state
            : addressFormValues.state,
        },
      },
      subscriberId: user.id,
      planCode: selectedPlan.code,
      subscriptionCode: "PIX-SUB",
      planIntervalUnit: selectedPlan.interval.unit,
      planIntervalLength: selectedPlan.interval.length,
      // userPayload: userPayload,
    }

    const res = await generateSubscribedPixCode(payment_data)
    setPixPayment(res)
    const time = new Date()
    time.setSeconds(time.getSeconds() + 600) // 10 minutes timer
    setPixTimer(time)
    setLoading(false)
  }
  const validateCheckboxTerms = () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.reject(
          intl.formatMessage({ id: "validateTermsAndPrivacy" })
        )
      }
      return Promise.resolve()
    },
  })
  const handleChangeState = (e) => {
    const value = e.target.checked
    setChecked(value)
  }

  const handleChangeStatePix = (ev) => {
    const value = ev.target.checked
    setCheckedPix(value)
  }

  const handleGetAllCities = useCallback(async (props) => {
    const response = await getAllCities(props)

    const listCities = _map(response, (item) => ({
      id: item.id,
      name: item.nome,
    }))

    listCities.sort((a, b) => a.name.localeCompare(b.name))

    setCities(listCities)
  }, [])

  const handleSearchAddressViaCep = useCallback(async (addressViaCep) => {
    const response = await searchAddressViaCep(addressViaCep)

    if (_get(response, "error.message", "")) {
      const adaptedErrors = errorsAdapter(_get(response, "error.message", ""))
      setErrors(adaptedErrors)
    }

    setAddressViaCep(response)
  }, [])

  const descriptionAcceptedTerms = () => {
    return (
      <div
        className={
          isRetryPayment
            ? cx("mb48", styles.descriptionAcceptedTerms)
            : cx("mb24", styles.descriptionAcceptedTerms)
        }
      >
        <p>{translate("descriptionAcceptedTerms")}</p>
      </div>
    )
  }
  const termsAndPrivacy = () => {
    return (
      <>
        <span className={styles.terms}>{translate("termsAndPrivacy2")}</span>
        <a
          href={terms}
          className={styles.termsAndPrivacy}
          target="_blank"
          rel="noreferrer"
        >
          <span>{translate("term")}</span>
        </a>
        <span className={styles.terms}>{translate("and")}</span>
        <a
          href={privacy}
          className={styles.termsAndPrivacy}
          target="_blank"
          rel="noreferrer"
        >
          <span>{translate("privacy")}</span>
        </a>
      </>
    )
  }
  const formRender = () => (
    <Form
      className={isRetryPayment ? "paymentRetryForm" : "paymentForm"}
      form={form}
      layout="vertical"
      onFinish={(formValues) => {
        if (isRetryPayment) {
          handleRetryPayment(PaymentMethodEnum.CREDIT_CARD, formValues)
        } else if (isPaymentPlan) {
          handlePaymentMethod(PaymentMethodEnum.CREDIT_CARD, formValues)
        } else {
          handleFinishForm(formValues)
        }
      }}
      validateMessages={VALIDATE_MESSAGES}
      initialValues={{
        cardNumber: "",
        nameCard: "",
        dueDate: "",
        codeCard: "",
        documentNumberCPF: data.documentNumber,
        couponCard: "",
      }}
    >
      {!isRetryPayment && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <Col span={8} xs={16} md={20}>
            <div className="flex flex-row justify-between items-center">
              <Form.Item
                className="w-100"
                label={
                  <>
                    {translate("coupon")}
                    <Tooltip
                      className={styles.customLabel}
                      color="#ffffff"
                      title={intl.formatMessage({
                        id: "tooltipMessage.coupon",
                      })}
                    >
                      <Icon name="TooltipInfo" style={{ marginLeft: 5 }} />
                    </Tooltip>
                    <span
                      style={{ fontWeight: "400", color: "#ea4c89" }}
                      className={localStyles.coupon__info}
                    >
                      <i>Válido apenas p/ cartão.</i>
                    </span>
                  </>
                }
                name="couponCard"
              >
                <Input
                  className="input-general"
                  onChange={(event) => handleCodeCoupon(event)}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={4} xs={8} md={4}>
            <Form.Item name="couponButtonCard" label=" " className="w-100">
              <Button
                loading={loadingCoupon}
                className={styles.couponButton}
                onClick={() => handleGenerateCouponDiscount()}
              >
                {translate("aplly")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Col span={12} xs={24} md={12}>
          <Form.Item
            label={translate("cardNumber")}
            name="cardNumber"
            rules={[{ required: true }, validateCardNumber]}
          >
            <InputMask
              mask="9999 9999 9999 9999 999"
              maskChar={null}
              className="input-general"
            >
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>
          </Form.Item>
        </Col>

        <Col span={12} xs={24} md={12}>
          <Form.Item
            label={translate("nameCard")}
            name="nameCard"
            rules={[{ required: true }, validateNameCard]}
          >
            <Input className="input-general" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 12, sm: 16, md: 24, lg: 24 }}>
        <Col span={4} xs={12} md={6}>
          <Form.Item
            label={translate("dueDate")}
            name="dueDate"
            rules={[{ required: true }, validateDueDate]}
          >
            <InputMask mask="99/99" maskChar={null} className="input-card">
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>
          </Form.Item>
        </Col>

        <Col span={4} xs={12} md={6}>
          <Form.Item
            label={translate("codeCard")}
            name="codeCard"
            rules={[{ required: true }, validateCodeCard]}
          >
            <InputMask mask="9999" maskChar={null} className="input-card">
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>
          </Form.Item>
        </Col>

        <Col span={12} xs={24} md={12}>
          <Form.Item
            label="CPF"
            name="documentNumberCPF"
            rules={[{ required: true }, validateDocumentCPF]}
            validateTrigger="onBlur"
          >
            <InputMask
              mask="999.999.999-99"
              maskChar={null}
              className="input-general"
            >
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>
      <div className={"mt16"}>
        <Form.Item
          name="cardCheck"
          className={"terms-check"}
          rules={[validateCheckboxTerms]}
          valuePropName="checked"
          style={{ marginBotom: "8px" }}
        >
          <Checkbox className={styles.checkBox} onChange={handleChangeState}>
            {termsAndPrivacy()}
          </Checkbox>
        </Form.Item>
      </div>
      {descriptionAcceptedTerms()}

      <Button
        className={styles.button_submit}
        type="primary"
        htmlType="submit"
        loading={loading}
        disabled={!checked}
      >
        {translate("continue")}
      </Button>
    </Form>
  )
  const renderHeaderSection = () => {
    return isRetryPayment ? (
      <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
        <Col
          span={24} /* xs={24} md={12} */ /* className={styles.planSection} */
        >
          <span className={localStyles.payment__title}>
            {translate("paymentTitle")}
          </span>
          <p className={localStyles.payment__subtitle}>
            <span>
              {isRetryPayment
                ? translate("paymentInfo")
                : translate("paymentDescription")}
            </span>
            {translate("paymentCancelAnytime")}
          </p>
        </Col>
      </Row>
    ) : (
      <div className={localStyles.payment__header__section}>
        <span className={localStyles.payment__title}>
          {translate("paymentTitle")}
        </span>
        <Row
          gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}
          style={isMobile ? { paddingTop: 17 } : {}}
        >
          <Col span={12} xs={24} md={12} className={styles.planSection}>
            <p>{translate("paymentDescription")}</p>
            <div>
              <span className={styles.paymentInfo}>
                {translate("paymentInfo")}
              </span>
              <span className={styles.cancelAnytime}>
                {translate("paymentCancelAnytime")}
              </span>
            </div>
          </Col>
          <Col
            /* span={12} */ span={12}
            xs={24}
            md={12}
            className={styles.priceSection}
          >
            {isDesktop && _get(pixPayment, "status", "") !== "CONCLUIDA" && (
              <SelectedPlan
                selectedPlan={selectedPlan}
                switchRender={switchRender}
                planName={planName}
                amountPlan={amountPlan}
                isPaymentPlan={isPaymentPlan}
                discount={valueCoupon}
                typeDiscount={typeCoupon}
                codeCoupon={codeCoupon}
              />
            )}
            {isMobile && _get(pixPayment, "status", "") !== "CONCLUIDA" && (
              <SelectedPlan
                selectedPlan={selectedPlan}
                switchRender={switchRender}
                planName={planName}
                amountPlan={amountPlan}
                isPaymentPlan={isPaymentPlan}
                discount={valueCoupon}
                typeDiscount={typeCoupon}
                codeCoupon={codeCoupon}
              />
            )}
          </Col>
        </Row>
      </div>
    )
  }

  const renderSubscriberAddress = () => {
    return (
      <div>
        <SubscriberAddress
          addressViaCep={addressViaCep}
          cities={cities}
          handleGetAllCities={handleGetAllCities}
          handleSearchAddressViaCep={handleSearchAddressViaCep}
          addressUpdate={handleUpdateAddressSubs}
        />
      </div>
    )
  }

  const handleUpdateAddressSubs = async (props) => {
    if (!props) {
      return
    }

    setAddressFormValues(props)

    const id = _get(user, "id")
    const response = await updateAddressSubscriberAnonimatta(id, {
      address: props,
    })

    if (response) {
      retrieveAddressSub()
    } else {
      return
    }
  }

  return (
    <div
      className={cx(
        "registration-general flex flex-column justify-center items-center",
        styles.userPayment
      )}
    >
      <div
        className={cx(
          "flex flex-column",
          /* styles.containerPayment, */
          localStyles.payment
        )}
      >
        <div className={cx("", styles.notCenter)}>{renderHeaderSection()}</div>
        {loadingAddress ? (
          <Loading />
        ) : (
          <>
            {!haveAddress && <div>{renderSubscriberAddress()}</div>}
            {isRetryPayment ? (
              <div className={localStyles.payment__check}>
                {/*  <div className="flex flex-column">
              <div className="flex flex-row items-center">
                <Radio value="1" checked={tab === "1"} />
                <span>{translate("creditCard")}</span>
              </div>
              <div className={localStyles.line}></div>
            </div> */}
                {formRender()}
              </div>
            ) : (
              haveAddress && (
                <Tabs
                  className="paymentTabs changePaymentMode"
                  defaultActiveKey={tab}
                  onChange={(event) => handleTabSelected(event)}
                >
                  <Tabs.TabPane
                    tab={
                      <TabItem
                        actualTab="card"
                        tab={tab}
                        style={{ margin: 0 }}
                      />
                    }
                    key="1"
                  >
                    {formRender()}
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={<TabItem tab={tab} style={{ margin: 0 }} />}
                    key="2"
                  >
                    <Form
                      form={form}
                      layout="vertical"
                      onFinish={handleFinishForm}
                      className={localStyles.payment__form__pix}
                    >
                      {/* CONCORDAR COM OS TERMOS DE USO PARA GERAR O QRCODE */}
                      <Row span={24}>
                        {!pixPayment /* && checkedPix */ /* selectedPlan */ && (
                          <p className="tc w-100">
                            <h4 className="f14 normal scorpion-main">
                              {translate("pixDescriptionTwo")}
                            </h4>
                            <br />
                            <Button
                              type="primary"
                              className="button-save"
                              onClick={handleCreatePixPayment}
                              loading={loading}
                            >
                              {translate("pixGenerateQRCode")}
                            </Button>
                          </p>
                        )}
                      </Row>
                      <Row className="w-100">
                        <Col span={24}>
                          {pixPayment && pixPayment.status !== "CONCLUIDA" && (
                            <div className="tc">
                              <h2
                                className="text-alert-pix css-current-storage"
                                style={{
                                  backgroundColor: "white",
                                  display: "inline-block",
                                }}
                              >
                                Não atualize ou saia dessa página! <br />{" "}
                                Aguarde a confirmação de pagamento. <br />
                              </h2>
                              <h2 className="scorpion-main">
                                {translate("pixExpiration")}
                              </h2>
                              <div className="flex flex-column items-center">
                                <PixExpirationTimer
                                  expiryTimestamp={pixTimer}
                                  onExpire={handleClearPix}
                                  paymentId={pixPayment.txid}
                                />
                                <img
                                  alt="Pix QRCode"
                                  src={`${_get(
                                    pixPayment,
                                    "imagemQrcode",
                                    ""
                                  )}`}
                                  className={cx("mb16", styles.pixContainer)}
                                />
                                <span className={styles.description}>
                                  {translate("copyTextPix")}
                                </span>
                                <div
                                  className={cx(
                                    "flex flex-row w-100 justify-between items-center",
                                    styles.pix__copy__section
                                  )}
                                >
                                  <div className={styles.pix__wrapper}>
                                    <div
                                      className={cx(
                                        "f12 normal scorpion-main ",
                                        styles.pixCopyContainer
                                      )}
                                    >
                                      {_get(pixPayment, "qrcode", "")}
                                    </div>
                                  </div>
                                  <CopyToClipboard
                                    text={_get(pixPayment, "qrcode", "")}
                                  >
                                    <button className={styles.buttonCopy}>
                                      <Icon name="Clipboard" />
                                      <span className="ml8">
                                        {translate("copyCode")}
                                      </span>
                                    </button>
                                  </CopyToClipboard>
                                </div>
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {pixPayment && pixPayment.status === "CONCLUIDA" && (
                            <SuccessAnimation />
                          )}
                        </Col>
                      </Row>
                      <Row>
                        {_get(pixPayment, "status", "") === "CONCLUIDA" && (
                          <p className="tc">
                            <h6 className="f14 normal scorpion-main">
                              {translate("pixActivateContinueText")}
                            </h6>
                            <br />
                            <Button
                              type="primary"
                              className="button-save"
                              onClick={() => {
                                update()
                                setVisible(false)
                              }}
                            >
                              {translate("pixActivateContinueButton")}
                            </Button>
                          </p>
                        )}
                      </Row>
                    </Form>
                  </Tabs.TabPane>
                </Tabs>
              )
            )}
          </>
        )}
      </div>
      <CustomModalErrors errors={errors} setErrors={setErrors} />
    </div>
  )
}

export default ChangePaymentMode
