import { UpOutlined } from "@ant-design/icons"
import translate from "@src/i18n"
import { Radio, Table, Tabs } from "antd"
import dayjs from "dayjs"
import React, { useState } from "react"
import styles from "../style.module.less"
import cx from "classnames"
import { useMedia } from "react-use-media"

const HistoryPlan = ({ pixInvoices, cardInvoices, handlePaymentVisible }) => {
  const [showLess, setShowLess] = useState(false)
  const [paymentHistoryTab, setPaymentHistoryTab] = useState("card")
  const { TabPane } = Tabs
  const mobile = useMedia({ maxWidth: 767 })

  const handleHistoryTabSelected = (type) => {
    setPaymentHistoryTab(type)
  }
  const paymentStatus = {
    1: "Em aberto",
    2: "Aguardando confirmação",
    3: "Pago",
    4: "Não Pago",
    5: "Atrasado",
  }

  const dataMobile = cardInvoices.map((item) => {
    return {
      data: {
        id: item.id,
        amount: item.amount,
        creation_date: item.creation_date,
        status: item.status,
      },
    }
  })
  const dataPixMobile = pixInvoices.map((item) => {
    return {
      data: {
        plan: item.order_plan_name,
        amount: item.order_order_value,
        creation_date: item.order_order_date,
        status: item.order_status_invoice,
      },
    }
  })

  const TabHistoryItem = ({ actualTab, tab }) => {
    if (actualTab === "card") {
      return (
        <div className="flex">
          <Radio value="card" checked={tab === "card"} />
          <span>{translate("creditCard")}</span>
        </div>
      )
    }

    return (
      <div className="flex">
        <Radio value="pix" checked={tab === "pix"} />
        <span>{translate("pix")}</span>
      </div>
    )
  }

  const pixColumns = [
    {
      title: "Plano",
      dataIndex: "order_plan_name",
      key: "order_plan_name",
      render: (text) => <span className={styles.tagId}>{text}</span>,
    },
    {
      title: "Valor",
      dataIndex: "order_order_value",
      key: "order_order_value",
      render: (text) => (
        <span className={styles.tagPrice}>R$ {(text / 100).toFixed(2)}</span>
      ),
    },
    {
      title: "Data",
      dataIndex: "order_order_date",
      key: "order_order_date",
      render: (text) => (
        <span className={styles.tagDate}>
          {dayjs(text).format("DD/MM/YYYY")}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "order_status_invoice",
      key: "order_status_invoice",
      render: (text) => {
        let color = "#808191"
        if (paymentStatus[text] === "Pago") color = "#7FBA7A"
        if (paymentStatus[text] === "Cancelada") color = "#D61F1F"
        return (
          <span className={styles.tagTable} style={{ color: color }}>
            {paymentStatus[text]}
          </span>
        )
      },
    },
  ]

  const renderColumnMobile = (data) => {
    const day = data.creation_date.day
    const month = data.creation_date.month
    const year = data.creation_date.year
    let color = "#808191"
    if (data.status.description === "Pago") color = "#7FBA7A"
    if (data.status.description === "Cancelada") color = "#D61F1F"
    return (
      <div className="flex flex-column w-100">
        <div className="flex flex-row justify-between mb8">
          <span className={styles.tagId}>{data.id}</span>
          <span className={styles.tagPrice}>
            R$ {(data.amount / 100).toFixed(2)}
          </span>
        </div>
        <div className="flex flex-row justify-between">
          <span className={styles.tagDate}>
            {dayjs(`${year}-${month}-${day}`).format("DD/MM/YYYY")}
          </span>
          <span className={styles.tagTable} style={{ color: color }}>
            {data.status.description}
          </span>
        </div>
      </div>
    )
  }
  const renderPixColumnMobile = (data) => {
    let color = "#808191"
    if (paymentStatus[data.status] === "Pago") color = "#7FBA7A"
    if (paymentStatus[data.status] === "Cancelada") color = "#D61F1F"
    if (data.status == "")
      return (
        <div className="flex flex-column w-100">
          <div className="flex flex-row justify-between mb8">
            <span className={styles.tagId}>{data.plan}</span>
            <span className={styles.tagPrice}>
              R$ {(data.amount / 100).toFixed(2)}
            </span>
          </div>
          <div className="flex flex-row justify-between">
            <span className={styles.tagDate}>
              {dayjs(data.creation_date).format("DD/MM/YYYY")}
            </span>
            <span className={styles.tagTable} style={{ color: color }}>
              {paymentStatus[data.status]}
            </span>
          </div>
        </div>
      )
  }

  const cardColumnsMobile = [
    {
      dataIndex: ["data"],
      key: ["data"],
      render: renderColumnMobile,
    },
  ]
  const pixColumnsMobile = [
    {
      dataIndex: ["data"],
      key: ["data"],
      render: renderPixColumnMobile,
    },
  ]

  const cardColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <span className={styles.tagId}>{text}</span>,
    },
    {
      title: "Valor",
      dataIndex: "amount",
      key: "amount",
      render: (text) => (
        <span className={styles.tagPrice}>R$ {(text / 100).toFixed(2)}</span>
      ),
    },
    {
      title: "Data",
      dataIndex: "creation_date",
      key: "creation_date",
      render: (creationDate) => {
        const day = creationDate.day
        const month = creationDate.month
        const year = creationDate.year
        return (
          <span className={styles.tagDate}>
            {dayjs(`${year}-${month}-${day}`).format("DD/MM/YYYY")}
          </span>
        )
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color = "#808191"
        if (status.description === "Pago") color = "#7FBA7A"
        if (status.description === "Cancelada") color = "#D61F1F"
        return (
          <span className={styles.tagTable} style={{ color: color }}>
            {status.description}
          </span>
        )
      },
    },
  ]

  return (
    <div
      className={cx(
        "pt24  flex flex-column w-100",
        styles.paymentHistoryContainer
      )}
    >
      <span className={cx("mb8", styles.subtitle)}>
        {translate("paymentHistory")}
      </span>
      {/*  <span className="kimberly fw6 f14 mb32">{translate("paymentCheck")}</span> */}
      <Tabs
        className="historyTabs"
        defaultActiveKey={paymentHistoryTab}
        onChange={(event) => handleHistoryTabSelected(event)}
        centered
      >
        <TabPane
          tab={<TabHistoryItem actualTab="card" tab={paymentHistoryTab} />}
          key="card"
        >
          <div className="overflow-auto">
            {mobile ? (
              <Table
                dataSource={dataMobile}
                columns={cardColumnsMobile}
                pagination={{ pageSize: 3 }}
                className="tableMobile"
              />
            ) : (
              <Table
                dataSource={cardInvoices}
                columns={cardColumns}
                pagination={{ pageSize: 3 }}
                className="tableDesktop"
              />
            )}
          </div>
        </TabPane>
        <TabPane
          tab={<TabHistoryItem actualTab="pix" tab={paymentHistoryTab} />}
          key="pix"
        >
          <div className="overflow-auto">
            {mobile ? (
              <Table
                dataSource={dataPixMobile}
                columns={pixColumnsMobile}
                pagination={{ pageSize: 3 }}
                className="tableMobile"
              />
            ) : (
              <Table
                dataSource={pixInvoices}
                columns={pixColumns}
                pagination={{ pageSize: 3 }}
                className="tableDesktop"
              />
            )}
          </div>
        </TabPane>
      </Tabs>

      {showLess && (
        <a
          onClick={handlePaymentVisible}
          className="kimberly underline f14 normal mv32"
        >
          {translate("seeLess")} <UpOutlined className="kimberly f14" />
        </a>
      )}
    </div>
  )
}

export default HistoryPlan
